.ytp-spinner {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 18;
  pointer-events: none;
  align-self: center;
}

.ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
}

.ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.ytp-spinner-left {
  right: 49%;
}

.ytp-spinner-right {
  left: 49%;
}

.ytp-spinner-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  border-color: var(--accent) var(--accent) transparent;
  border-radius: 50%;
  border-width: 3px;
}

.ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: transparent;
  -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: transparent;
  -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-linspin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}





