.section-2 {
  position: relative;
  padding-top: 80px;
}

.section2-slider {
  flex: 1;
  position: relative;
  height: 532px;
  overflow: hidden;
  display: flex;
  align-items: center;
}


.section3-slick-item {
  width: 100%;
  border-radius: 40px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  display: flex !important;
  height: 164px;
  align-items: center;
  margin-bottom: 20px;
  opacity: 0.5;
  transition: var(--transition);
  cursor: pointer;
}
.section3-slick-item.current, .slick-current .section3-slick-item{
  opacity: 1;
  pointer-events: none;
}
.section3-preview {
  flex: 1;
}

.section2-slider-img-item {
  height: 540px;
  border-radius: 40px;
}

.section2-slider-img img {
  height: 100%;
  width: auto;
  border-radius: 40px;
  object-fit: contain;
}

.section3-pagination {
  width: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  flex-direction: column;
}

.section3-pagination-center {
  font-size: 40px;
  line-height: 48px;
  font-family: var(--font-ermilov);
  width: 120px;
  min-width: 120px;
  height: 120px;
  border: 2px solid var(--accent);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section3-pagination-circle {
  opacity: 0.5;
  border: 2px solid var(--accent);
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 32px;
  margin: 40px 0;
  cursor: pointer;
  transition: var(--transition);
}

.section3-pagination-circle.small {
  opacity: 0.3;
  width: 8px;
  min-width: 8px;
  border-radius: 16px;
  height: 8px;
  margin: 0;
}

.section3-pagination-circle:hover {
  background-color: var(--accent);
}

.section2-item {
  margin-bottom: 24px;
}

.section2-item:last-child {
  margin-bottom: 0;
}

.section2-item p {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 24px;
  border-radius: 24px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  max-width: 560px;
}

.section2-item-idx {
  position: absolute;
  font-size: 24px;
  line-height: 48px;
  width: 80px;
  height: 80px;
  border: 2px solid var(--accent);
  border-radius: 80px;
  font-family: var(--font-ermilov);
  bottom: -50px;
  right: -25px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section2-item img {
  max-width: 100%;
}