#section-1 {
  position: relative;
  /*overflow-x: hidden;*/
}
.section-1 {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 80px;
}
.section-1-btn {
position: relative;
}
.section-1-bg {
  position: absolute;
}
.section-1-img {
  width: 560px;
  position: absolute;
  top: 40px;
  margin-right: 210px;
}

.section-1-content {
  position: relative;
}

.section-1 h1 {
  max-width: 347px;
  padding-top: 80px;
}

.section-1 h2 {
  margin-bottom: 60px;
  text-align: right;
  max-width: 667px;
}

.section-1 h2 img {
  width: 88px;
  margin: 0 20px;
  position: relative;
  bottom: 6px;
}

.section-1-btn {
  align-self: center;
  margin-right: 115px;
}

.section-1-text-2 {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}