.section-3 {
}
.section3-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
.section3-item {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.section3-item.item-1 {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  display: flex;

}
.section3-items.accent-glow:after {
  bottom: auto;
  top: -50px;
  left: -50px;
}
.section3-item.item-2 {
  grid-column: 3 / 4;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section3-item.item-3 {
  grid-column: 1 / 3;
  grid-row: 2/5;
}
.section3-item.item-4 {
  grid-column: 3 / 4;
  grid-row: 3/5;
}
.section3-item-idx {
  color: var(--accent);
  font-family: var(--font-ermilov);
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  margin-top: 20px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: var(--accent);
  -webkit-text-stroke-width: 1px;
}
.section3-item-img {
  width: auto;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  align-self: flex-start;
  border-radius: 20px;
}
.section3-item-logo {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 136px;
  min-width: 136px;
  margin-right: 40px;
}
.section3-item-logo img{
  width: 100px;
  position: relative;
}
.section3-item .circle-btn {
  margin-right: 105px;
}
.section3-item-title {
  z-index: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.section3-item-youtube {
  position: absolute;
  right: 80px;
  bottom: 0;
  width: 180px;
}
.section3-item-youtube img {
  width: 100%;
  position: relative;
  height: auto;
}
