:root {
  --main-bg-color: #141414;
  --text-color: #ffffff;
  --tint-color: #ffffff;
  --muted-color: rgba(255,255,255,0.8);
  --divider: rgba(255,255,255,0.5);
  --border-color: rgba(255,255,255,0.5);

  --accent: #83C000;
  --danger: #FF2A1D;


  --font-refular: 'OpenSans-Regular', sans-serif;
  --font-semi-bold: 'OpenSans-SemiBold', sans-serif;
  --font-bold: 'OpenSans-Bold', sans-serif;
  --font-ermilov: 'Ermilov', sans-serif;

  --transition: all .3s ease;
  --transition-1s: all 1s ease;
}
