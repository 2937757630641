@font-face {/*400*/
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/OpenSans-Regular.svg#webfont') format('svg');
}

@font-face {/*600*/
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
  url('../fonts/OpenSans-SemiBold.svg#webfont') format('svg');
}

@font-face {/*700*/
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold.svg#webfont') format('svg');
}

@font-face {
  font-family: 'Ermilov';
  src: url('../fonts/Ermilov-Bold.eot');
  src: url('../fonts/Ermilov-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ermilov-Bold.woff') format('woff'),
  url('../fonts/Ermilov-Bold.ttf') format('truetype'),
  url('../fonts/Ermilov-Bold.svg#webfont') format('svg');
}

/* -------------------------- */
