header {
  height: 100px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;

  padding: 0 40px;
  display: flex;
  align-items: flex-end;
}

.header {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: var(--transition);
}

header button {
}

header.scrolled {
  background: rgba(20, 22, 30, 0.4);
  backdrop-filter: blur(20px);
}

header.scrolled .header {
  border-bottom-color: #fff;
}

.header-logo {
  width: 120px;
  height: auto;
  transition: transform .5s ease;
  cursor: pointer;
  margin-right: 40px;
}


.header-right, .header-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

nav {
  margin: 0 -12px;
}

nav ul {
  list-style: none;
  display: flex;
  font-size: 14px;
}

nav ul li {
  padding-bottom: 0;

}

nav a {
  font-size: 14px;
  font-family: var(--font-semi-bold);
  line-height: 18px;
  color: rgba(255, 255, 255, 0.3);
  margin: 0 12px;
  transition: var(--transition);
  white-space: nowrap;
}

nav a.active {
  color: var(--text-color);
}

.header-lang-select {
  margin-left: 40px;
  border-radius: 12px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-semi-bold);
  color: var(--main-bg-color);
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid transparent;
  width: 78px;
  min-width: 78px;
  height: 40px;
  padding: 2px;
  background-color: transparent;
}

.header-lang-select-value {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  transition: var(--transition);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  padding: 0 16px;
}

.header-lang-select-value:after {
  content: '';
  position: absolute;
  left: -3px;
  top: -3px;
  bottom: -3px;
  right: -3px;
  border-radius: 12px;
  border: 1px solid #fff;
  pointer-events: none;
}

.header-lang-select:hover .header-lang-select-value, .header-lang-select.visible .header-lang-select-value {
  background-color: #373737;
}


.header-lang-select-hidden {
  position: absolute;
  top: 20px;
  left: -1px;
  right: -1px;
  padding-top: 18px;
  color: rgba(255, 255, 255, 0.3);
  background: #373737;
  border-radius: 10px;
  display: none;
  text-transform: uppercase;
}


.header-lang-select.visible .header-lang-select-hidden {
  display: block;
}


.header-lang-select-hidden-item {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  width: 100%;
  padding: 8px 16px;
}

.header-lang-select-hidden-item:hover {
  color: var(--accent);
}

.header-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}