@media only screen and (max-width: 1279px) {
  .section-1 h2 {
    max-width: 326px;
  }

  .section-1 h2 img {
    height: 44px;
    width: auto;
  }

  .section-1-btn:after {
    content: '';
    width: 120px;
    height: 120px;
    background: var(--accent);
    opacity: 0.6;
    filter: blur(60px);
    top: -40px;
    right: -40px;
    pointer-events: none;
    position: absolute;
  }

}

@media only screen and (max-width: 991px) {
  .section-1-img {
    top: 0;
    left: auto;
    margin-right: auto;
    max-width: 80%;
    min-width: 225px;
  }

  .section-1 {
    flex-wrap: wrap;
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-1 h1 {
    width: 100%;
    padding-top: 0px;
  }



  .section-1-text-2 {
    padding-top: 60px;
    width: 100%;
  }
  .section-1-btn {
    align-self: flex-start;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
