@media only screen and (max-width: 1279px) {
  .section-2 {
    padding-top: 240px;
  }
  .section2-item-idx:after {
    content: '';
    width: 80px;
    height: 80px;
    background: var(--accent);
    opacity: 0.6;
    filter: blur(90px);
    top: -20px;
    right: -20px;
    pointer-events: none;
    position: absolute;
  }
}

@media only screen and (max-width: 991px) {
  .section-2 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-2 {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
