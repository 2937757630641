@media only screen and (max-width: 1279px) {

}

@media only screen and (max-width: 991px) {
  .section3-items {
    display: block;
    margin-bottom: 16px;
  }

  .section3-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border-radius: 24px;
    padding: 8px;
  }

  .section3-item p {
    padding-left: 12px;
  }

  .section3-item-idx {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
  }

  .section3-item-img {
    border-radius: 8px;
    max-height: 70vh !important;
  }

  .section3-item .mb-40 {
    margin-bottom: 16px;
  }

  .section3-item-title {
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 0;
    margin-bottom: 20px;
  }

  .section3-item.item-4 .section3-item-title {
    margin-bottom: 12px;
  }

  .section3-item.item-5 {
    display: grid !important;
    align-items: flex-start !important;
    grid-template-columns: repeat(6, 1fr);
  }
  .section3-item.item-5:after {
    content: '';
    width: 199px;
    height: 199px;
    background: var(--accent);
    opacity: 0.6;
    filter: blur(150px);
    bottom: 0;
    pointer-events: none;
    position: absolute;
  }
  .section3-item .section3-item-title {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  .section3-item-logo {
    width: 120px;
    height: 120px;
    min-width: 120px;
    margin-left: 4px;
    grid-column: 1 / 2;
    grid-row: 2/ 3;
    margin-right: 0;
  }

  .section3-item .circle-btn {
    grid-column: 2 / 7;
    grid-row: 2/ 2;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
