@media only screen and (max-width: 1550px) {
  main {
    width: 100vw;
    overflow-x: hidden;
    margin: 0 !important;
    max-width: 100%;
  }

}

@media only screen and (max-width: 1279px) {
  section {
    padding-bottom: 40px;
  }

  h1 {
    font-size: 40px;
    line-height: 44px;
    max-width: 222px;
  }

  h2 {
    font-size: 40px;
    line-height: 44px;
    max-width: 222px;
  }

  h3 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 36px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }

  main {
    padding-top: 180px !important;
  }

  body {
    font-size: 14px;
    line-height: 22px;
  }

  .mb-80 {
    margin-bottom: 40px;
  }

  .mb-40 {
    margin-bottom: 20px;
  }

  .mb-20 {
    margin-bottom: 16px;
  }
  #section-5 {
    padding-bottom: 20px;
  }
  #section-5 h3{
    max-width: 500px!important;
  }
}

@media only screen and (max-width: 767px) {
  main {
    padding: 180px 16px 16px !important;
  }
}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
