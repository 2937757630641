::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
  /*overflow: hidden;*/
  overflow-x: hidden;

}

body {
  overscroll-behavior-y: none;
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 18px;
  line-height: 28px;
  background: url("../images/bg.png") no-repeat -350px -1150px;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background: url("../images/bg_2.png") no-repeat center 2100px;
  background-size: 120%;

}

main {
  display: flex;
  flex-direction: column;
  padding: 140px 40px 0 !important;
  flex: 1;
  position: relative;
}
.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

h1 {
  font-size: 80px;
  line-height: 88px;
  font-family: var(--font-ermilov);
}

h2 {
  font-size: 80px;
  line-height: 88px;
  font-family: var(--font-ermilov);
}

h3 {
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 80px;
  font-family: var(--font-ermilov);
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

.btn {
  height: 40px;
  border-radius: 12px;
  transition: var(--transition);
  font-family: var(--font-semi-bold);
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: none;
}

.btn:active {
  box-shadow: none;
}

.btn-lg {
  height: 64px;
  font-size: 18px;
  line-height: 28px;
}

.btn-primary {
  border-radius: 12px;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #fff;
  color: var(--main-bg-color);
  padding: 2px;
}

.btn-primary > div {
  background-color: #fff;
  border-radius: 9px;
  padding: 0 24px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.btn-primary:focus {
  box-shadow: none;
  border-color: #fff;
  background-color: transparent;
  color: var(--main-bg-color);
}

.btn-primary:active:focus {
  border-color: var(--accent);
  background-color: var(--accent);
  box-shadow: none;
}

.btn-primary:active:focus > div {
  background-color: var(--accent);
  color: #fff;
}

.btn-primary:hover {
  border-color: #fff;
  background-color: #fff;
  color: var(--main-bg-color);
}

.btn-outline-primary {
  padding: 2px;
  color: #fff;
  box-shadow: none;
  border: 1px solid #fff;
  background-color: transparent;
}

.btn-outline-primary > div {
  border-radius: 9px;
  padding: 0 24px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.btn-outline-primary:active:focus {
  color: #fff;
  border-color: var(--accent);
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-primary:active:focus > div {
  background-color: rgba(131, 192, 0, 0.1);
  color: var(--accent);
}

.btn-outline-primary:focus {
  color: #fff;
  box-shadow: none;
  border-color: #fff;
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-outline-primary:hover > div {
  background: rgba(255, 255, 255, 0.1);
}

.btn-outline-secondary {
  color: var(--accent);
  box-shadow: none;
  border-color: var(--accent);
  background-color: transparent;
}


.btn-outline-secondary:active:focus {
  color: var(--accent);
  border-color: var(--accent);
  background-color: rgba(48, 255, 243, .05);
  box-shadow: none;
}

.btn-outline-secondary:focus {
  color: var(--accent);
  box-shadow: none;
  border-color: var(--accent);
  background-color: transparent;
}

.btn-outline-secondary:hover {
  color: var(--accent);
  border-color: var(--accent);
  background-color: rgba(48, 255, 243, .15)
}


.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  background: rgba(255, 255, 255, .1);
  color: rgba(255, 255, 255, .1);
}

.btn > div {
  z-index: 1;
  position: relative;
}

ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  padding-bottom: 8px;
}

p {
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

a {
  color: rgba(255, 255, 255, .5);
  text-decoration: none;
  outline: none;
  transition: var(--transition);
}

a:hover {
  outline: none;
  color: var(--text-color);
}

a:active {
  outline: none;
  color: var(--tint-color);
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-16 {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-semiBold {
  font-family: var(--font-semi-bold);
}

.text-muted-14 {
  color: var(--muted-color) !important;
  font-size: 14px;
  line-height: 18px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

.text-tint {
  color: var(--tint-color);
}


.cur-pointer {
  cursor: pointer;
}

section {
  padding-bottom: 120px;
}

section:last-child {
  border-bottom: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.action-el {
  cursor: pointer;
  transition: var(--transition);
}

.action-el:hover {
  color: var(--tint-color);
}


.section-title-img img {
  width: 88px;
  margin: 0 20px;
  position: relative;
  bottom: 6px;
}

.Toastify__toast-theme--light {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #43454B;
}

.Toastify__progress-bar--error {
  background: var(--danger);
}

.white-noise {
  height: 100%;
  width: 100%;
  opacity: 0.08;
  background: url("../noise.gif");
  background-size: auto;
  background-repeat: repeat;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.text-bordered {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
}

.text-tint {
  color: var(--accent);
}

.ps-20 {
  padding-left: 20px;
}

.pe-20 {
  padding-right: 20px;
}
.accent-glow {
  position: relative;
}
.accent-glow:after {
  content: '';
  width: 199px;
  height: 199px;
  background: var(--accent);
  opacity: 0.6;
  filter: blur(150px);
  bottom: 0;
  pointer-events: none;
  position: absolute;
}

.bg-circles:before {
  content: '';
  position: absolute;
  width: 155px;
  height: 155px;
  border-radius: 50%;
  opacity: 0.5;
  background: #8000FF;
  filter: blur(55px);
  left: -50px;
  bottom: -20px;
  z-index: -1;
}
.bg-circles:after {
  content: '';
  position: absolute;
  width: 155px;
  height: 155px;
  border-radius: 50%;
  opacity: 0.5;
  background: #83C000;
  top: -50px;
  filter: blur(55px);
  right: -50px;
  z-index: -1;
}